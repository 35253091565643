import Link from 'next/link';
import { usePathname } from 'next/navigation';
import {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

interface CustomProps<T> extends HTMLAttributes<T> {
  href?: {
    pathname?: string;
    query?: any;
  };
  pages?: string;
  variant?: string;
  startContent?: ReactNode | string;
  endContent?: ReactNode | string;
  target?: string;
}

export const CustomLink: FC<CustomProps<Element>> = ({
  children,
  href,
  className,
  pages,
  variant,
  startContent,
  endContent,
  target,
  ...props
}) => {
  const pathname = usePathname();
  const [active, setActive] = useState(false);

  const variants = useMemo(() => {
    let _var = variant || 'default';
    if (active) {
      switch (_var) {
        case 'default':
          return "after:content-[''] after:absolute after:bottom-0 after:inset-x-0 after:h-[1px] after:rounded-[2px] after:bg-gray-700 text-gray-700 hover:text-gray-600 font-semibold";
        case 'primary':
          return "after:content-[''] after:absolute after:bottom-0 after:inset-x-0 after:h-[1px] after:rounded-[2px] after:bg-primary text-primary hover:text-primary-dark font-semibold";
        case 'secondary':
          return "after:content-[''] after:absolute after:bottom-0 after:inset-x-0 after:h-[1px] after:rounded-[2px] after:bg-secondary-500 text-secondary-500 hover:text-secondary-400 font-semibold";
        case 'danger':
          return "after:content-[''] after:absolute after:bottom-0 after:inset-x-0 after:h-[1px] after:rounded-[2px] after:bg-danger-500 text-danger-500 hover:text-danger-400 font-semibold";
        case 'primary-header':
          return "after:content-[''] after:absolute after:bottom-0 after:inset-x-0 after:h-[1px] after:rounded-[2px] after:bg-primary text-primary hover:text-gray-400 font-semibold";
      }
    } else {
      switch (_var) {
        case 'default':
          return 'outline-none focus:text-gray-700 hover:text-gray-600';
        case 'primary':
          return 'outline-none focus:text-primary hover:text-primary-dark';
        case 'seconday':
          return 'outline-none focus:text-secondary-500 hover:text-secondary-400';
        case 'danger':
          return 'outline-none focus:text-danger-500 hover:text-danger-400';
        case 'primary-header':
          return 'outline-none focus:text-primary hover:text-gray-400';
      }
    }
  }, [variant, active]);

  useEffect(() => {
    if (pathname === href?.pathname || pathname.includes(pages as string))
      setActive(true);
    else setActive(false);
  }, [pathname, pages, href?.pathname]);

  return (
    <Link
      {...props}
      href={{ pathname: href?.pathname, query: href?.query }}
      scroll={true}
      className={twMerge(
        'group relative inline-flex items-center gap-1 rounded-sm font-medium  duration-300 ease-in-out',
        variants,
        className ? className : ''
      )}
      target={target}
    >
      {startContent || null}
      {children}
      {endContent || null}
    </Link>
  );
};
