import { create } from 'zustand';

export const enum ThemeBase {
  light = 'light',
  dark = 'dark',
}

const enum ThemeColors {
  default = 'default',
  black = 'black',
  purple = 'purple',
}

type ThemeBaseTypes = keyof typeof ThemeBase | string;
type ThemeColorTypes = keyof typeof ThemeColors | string;

interface PageTheme {
  pageTheme: ThemeColorTypes;
  themeBase: ThemeBaseTypes;
  setThemeBase: (theme: ThemeBaseTypes) => void;
  bgColor: string;
  setPageTheme: (theme: ThemeColorTypes) => void;
  navLogo: string;
  setNavLogo: (logo: ThemeBaseTypes) => void;
  reset: () => void;
}

const defaultBGColor = 'bg-white';

export const usePageTheme = create<PageTheme>()((set, get) => ({
  pageTheme: 'default',
  themeBase: 'light',
  setThemeBase: (theme: ThemeBaseTypes) => {
    switch (theme.toLocaleLowerCase()) {
      case ThemeColors.purple:
      case ThemeColors.black:
        set({ themeBase: ThemeBase.dark });
        get().setNavLogo(ThemeBase.dark);
        break;
      default:
        set({ themeBase: ThemeBase.light });
        get().setNavLogo(ThemeBase.light);
        break;
    }
  },
  bgColor: defaultBGColor,
  setPageTheme: (theme: string) => {
    get().setThemeBase(theme);

    switch (theme.toLocaleLowerCase()) {
      case ThemeColors.default:
        set({ bgColor: defaultBGColor });
        break;
      case ThemeColors.purple:
        set({ bgColor: 'bg-[#352A61]' });
        break;
      case ThemeColors.black:
        set({ bgColor: 'bg-black' });
        break;
      default:
        set({ bgColor: defaultBGColor });
        break;
    }
  },
  navLogo: '/images/mrcool-logo.svg',
  setNavLogo: (baseTheme: ThemeBaseTypes) => {
    switch (baseTheme) {
      case ThemeBase.dark:
        set({ navLogo: '/images/mrcool-logo-white.svg' });
        break;
      default:
        set({ navLogo: '/images/mrcool-logo.svg' });
        break;
    }
  },
  reset() {
    set({
      pageTheme: 'default',
      themeBase: 'light',
      bgColor: defaultBGColor,
      navLogo: '/images/mrcool-logo.svg',
    });
  },
}));
