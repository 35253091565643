'use client';

import React, {
  FC,
  HTMLAttributes,
  useEffect,
  useMemo,
  useState,
  memo,
  useCallback,
  useRef,
} from 'react';
import { usePathname, useRouter } from 'next/navigation';
import Link from 'next/link';

import {
  MdMenu,
  MdSearch,
  MdClose,
  MdGroups,
  MdArrowBackIos,
} from 'react-icons/md';
import { useMeasure, useWindowScroll, useWindowSize } from 'react-use';
import AxiosInstance, { AxiosRequestConfig } from 'axios';
import QueryString from 'qs';

import ModalComponent from '../modal/ModalComponent';
import MobileProductModal from './MobileProductModal';
import DesktopProductModal from './DesktopProductModal';
import { CustomLink } from '../link';
import { Button } from '../button';
import { Icon } from '../icons';

import { useSearchProducts } from 'stores/search/ProductSearch';

import { NavigationItem } from 'types';
import { ProductCategories } from 'stores/types';

import './Navbar.scss';
import { useMapStore } from '@/stores/map/mapStore';
import { useStore } from 'zustand';
import { APIProvider } from '@vis.gl/react-google-maps';
import { API_KEY } from '@/utils/constants';
import LocationWidget from '../location/locationWidget';
import { ThemeBase, usePageTheme } from '@/stores/page-theme';
import { twMerge } from 'tailwind-merge';

interface Props<T> extends HTMLAttributes<T> {
  variant?: string | 'default' | 'primary' | 'secondary' | 'danger';
  position?: string | 'fixed' | '';
  className?: string;
  textColor?: string;
  logoImages?: string;
  navigationTextColor?: string;
  colorHeader?: string;
  nav: NavigationItem[];
}

interface DrawerProps {
  id: number;
  isOpen: boolean;
  name?: string;
  dropdown?: boolean;
  products?: {
    data: {
      id: number;
      attributes: {
        name: string;
        slug: string;
      };
    }[];
  };
}

interface ProductData {
  id: number | 'all';
  productDropdown?: {
    id: number;
    attributes: {
      name: string;
      slug: string;
      thumbnail?: {
        data?: {
          attributes?: {
            url?: string;
          };
        };
      };
    };
  }[];
  draw?: {
    id: number;
    isOpen: boolean;
  };
  header?: {
    id: number;
    name: string;
  };
}

interface SearchProduct {
  id: number;
  attributes: {
    name: string;
    slug: string;
    description?: string;
    createdAt?: string;
    new_release?: boolean;
  };
}

interface DropdownProduct {
  id: number;
  attributes: {
    name: string;
    slug: string;
    thumbnail?: {
      data?: {
        attributes?: {
          url?: string;
        };
      };
    };
  };
}

interface ThemeNavigation {
  desktop: {
    color: {
      default: string;
      active: string;
      hover: string;
    };
  };
  mobile: {
    color: {
      default: string;
      active: string;
      hover: string;
    };
  };
}

const MemoizedDesktopProductModal = memo(DesktopProductModal);
const MemoizedMobileProductModal = memo(MobileProductModal);

const Navbar: FC<Props<Element>> = ({ ...props }) => {
  const { bgColor, navLogo, themeBase } = useStore(usePageTheme, (state) => ({
    bgColor: state.bgColor,
    navLogo: state.navLogo,
    themeBase: state.themeBase,
  }));
  const { getDispatchData } = useStore(useMapStore, (state) => ({
    getDispatchData: state.getDispatchData,
  }));
  const axios = AxiosInstance.create({
    baseURL: process.env.API_ENDPOINT,
  });
  const [productCategories, setProductCategories] = useState<
    ProductCategories[]
  >([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isSelectedCategory, setIsSelectedCategory] = useState<string>('');
  const [isDropdownProducts, setIsDropdownProducts] = useState<boolean>(false);
  const router = useRouter();
  const pathname = usePathname();
  const { products, getSearchProduct, isLoading } = useSearchProducts();
  const [dataProducts, setDataProducts] = useState<SearchProduct[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [resetColorNavbar, setResetColorNavbar] = useState<boolean>(false);
  const [navBGColor, setNavBGColor] = useState<string>('');
  const [navLogoImg, setNavLogoImg] = useState<string>(
    '/images/mrcool-logo.svg'
  );
  const [isDrawer, setIsDrawer] = useState<DrawerProps[]>([]);
  const [removeFix, setRemoveFix] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [dataProduct, setDataProduct] = useState<ProductData | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ref, component] = useMeasure<HTMLHeadElement>();
  const { width } = useWindowSize();
  const { y } = useWindowScroll();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const prefixCategories = '/product-categories';
  const hasInitializedFromUrl = useRef(false);
  const [category, setCategory] = useState<string | null>(null);
  const modalStateRef = useRef({
    isOpen: false,
    category: '',
  });
  const isLoadingRef = useRef(false);
  const prevProductsRef = useRef<DropdownProduct[]>([]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
    setDataProducts([]);
    setDataProduct(null);
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    []
  );

  const clearInput = useCallback(() => {
    setInputValue('');
  }, []);

  const clientHeight = useMemo(() => {
    let h = '0px';
    if (width >= 1024) {
      h = `${component.height}px`;
    }
    return h;
  }, [component.height, width]);

  const scrollNavbar = useMemo(() => {
    let background: string =
      'fixed transition-all duration-[0.1ms] md:duration-[300ms] w-full top-0 start-0 before:-top-5 before:start-0 before:h-5 animate-fade-in-down text-gray-600 backdrop-blur-sm shadow-sm';
    if (y && y > 150) {
      return {
        background,
        color: 'text-gray-700',
      };
    } else {
      return {
        background: '',
        color: '',
      };
    }
  }, [y]);

  const filters = useMemo(() => {
    let search = QueryString.stringify({
      sort: ['id:desc'],
      filters: {
        name: {
          $containsi: inputValue || '',
        },
      },
      fields: ['name', 'description', 'slug', 'createdAt', 'new_release'],
      pagination: {
        pageSize: 5,
        page: 1,
      },
      populate: {
        thumbnail: '*',
      },
    });
    let parse = QueryString.parse(search);
    return parse;
  }, [inputValue]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;
      if (currentScrollTop < 30) {
        setRemoveFix(true);
      } else {
        setRemoveFix(false);
      }
      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setIsVisible(false);
      } else {
        // Scrolling up
        setIsVisible(true);
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    if (filters && inputValue.length > 1) {
      const timeOut = setTimeout(() => {
        getSearchProduct({ params: filters });
      }, 1000);
      return () => clearTimeout(timeOut);
    }
  }, [filters, inputValue.length, getSearchProduct]);

  useEffect(() => {
    let filter: DrawerProps[] = [];

    props.nav
      ?.filter((item: NavigationItem) => item?.dropdown)
      .map((items: NavigationItem) => {
        filter.push({
          ...items,
          isOpen: false,
        });
      });
    setIsDrawer(filter);
  }, [props.nav]);

  const onOpenDrawer = useCallback(
    (id: number | 'all', item?: NavigationItem): void => {
      setCategory(null);
      if (dataProduct?.id === id) {
        modalStateRef.current = { isOpen: false, category: '' };
        setDataProduct(null);
        setIsDrawer(isDrawer?.map((item) => ({ ...item, isOpen: false })));
        return;
      }

      if (id === 'all') {
        modalStateRef.current = {
          isOpen: true,
          category: productCategories?.[0]?.attributes?.slug || '',
        };
        setDataProduct({ id: 'all' });
        setIsDrawer(isDrawer?.map((item) => ({ ...item, isOpen: false })));

        if (!isSelectedCategory) {
          setIsSelectedCategory(modalStateRef.current.category);
        }
        return;
      }

      const drawerData = isDrawer?.find((item) => item?.id === id);
      setDataProduct({
        id: id as number,
        productDropdown: drawerData?.products?.data,
        draw: drawerData,
        header: item,
      });
      setIsDrawer(
        isDrawer?.map((item) => ({ ...item, isOpen: item?.id === id }))
      );
    },
    [dataProduct?.id, isDrawer, productCategories, isSelectedCategory]
  );

  const getProductCategories = useCallback(
    async (params?: AxiosRequestConfig) => {
      if (isLoadingRef.current) return;
      isLoadingRef.current = true;
      setIsLoadingCategories(true);

      try {
        const response = await axios.get(prefixCategories, params);
        setProductCategories(response.data.data);
      } catch (error) {
        console.error('Error fetching product-categories:', error);
      } finally {
        setIsLoadingCategories(false);
        setTimeout(() => {
          isLoadingRef.current = false;
        }, 1000);
      }
    },
    [axios, prefixCategories]
  );

  const filterCategory = useMemo(() => {
    let search = QueryString.stringify({
      sort: ['position:asc'],
      populate: {
        products: {
          populate: '*',
        },
      },
    });
    let parse = QueryString.parse(search);
    return parse;
  }, []);

  useEffect(() => {
    if (filterCategory) getProductCategories({ params: filterCategory });
  }, [filterCategory, getProductCategories]);

  useEffect(() => {
    if (
      !hasInitializedFromUrl.current &&
      pathname?.includes('/product/') &&
      !modalStateRef.current.isOpen &&
      !isLoadingRef.current
    ) {
      const _selected = productCategories?.find((item) =>
        item?.attributes?.products?.data?.some((item: DropdownProduct) =>
          pathname?.includes(item.attributes.slug)
        )
      );
      if (_selected?.attributes?.slug) {
        hasInitializedFromUrl.current = true;
        setIsSelectedCategory(_selected.attributes.slug);
      }
    }
  }, [pathname, productCategories]);

  const productSelected = useMemo(() => {
    if (!isSelectedCategory || !productCategories?.length) {
      prevProductsRef.current = [];
      return [];
    }

    const category = productCategories.find(
      (item) => item?.attributes?.slug === isSelectedCategory
    );

    const result = category?.attributes?.products?.data || [];

    if (JSON.stringify(result) !== JSON.stringify(prevProductsRef.current)) {
      prevProductsRef.current = result;
    }

    return prevProductsRef.current;
  }, [isSelectedCategory, productCategories]);

  useEffect(() => {
    if (inputValue?.length > 0) {
      setDataProducts(products);
    }
  }, [products, inputValue]);

  useEffect(() => {
    setResetColorNavbar(isDropdownProducts);
  }, [isDropdownProducts]);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isMobileMenuOpen]);

  const handleCloseModal = useCallback(() => {
    setDataProduct(null);
    setIsMobileMenuOpen(false);
  }, []);

  const handleDropdownButtonClick = useCallback(
    (slug: string) => {
      router.push(`/product/${slug}`);
      setTimeout(() => {
        setIsMobileMenuOpen(false);
        onClose();
      }, 0);
    },
    [router, onClose]
  );

  useEffect(() => {
    getDispatchData();

    const mediaQuery = window.matchMedia('(min-width: 1024px)');

    const handleResize = () => {
      if (mediaQuery.matches) {
        setIsMobileMenuOpen(false);
      }
    };

    handleResize();

    mediaQuery.addEventListener('change', handleResize);

    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  useEffect(() => {
    if (pathname.includes('product')) {
      const getProductCategory = productCategories.find((category) =>
        category.attributes.products?.data.find((data) =>
          pathname.includes(data.attributes.slug)
        )
      );

      if (getProductCategory?.attributes.name)
        setCategory(getProductCategory.attributes.name);
    } else {
      setCategory(null);
    }
  }, [pathname]);

  const [pageThemebase, setPageThemebase] = useState<ThemeNavigation>({
    desktop: {
      color: { active: '', hover: 'text-gray-400', default: 'text-gray-600' },
    },
    mobile: {
      color: { active: '', hover: 'text-gray-400', default: 'text-gray-600' },
    },
  });

  useEffect(() => {
    const theme: ThemeNavigation =
      themeBase === ThemeBase.dark
        ? {
            desktop: {
              color: {
                active: '',
                hover: 'text-gray-400',
                default: 'text-gray-100',
              },
            },
            mobile: {
              color: {
                active: '',
                hover: 'text-gray-400',
                default: 'text-gray-600',
              },
            },
          }
        : {
            desktop: {
              color: {
                active: '',
                hover: 'text-gray-400',
                default: 'text-gray-600',
              },
            },
            mobile: {
              color: {
                active: '',
                hover: 'text-gray-400',
                default: 'text-gray-600',
              },
            },
          };

    setPageThemebase(theme);
    setNavLogoImg(navLogo);
    setNavBGColor(bgColor);
  }, [bgColor]);

  return (
    <>
      <APIProvider
        apiKey={API_KEY}
        libraries={['places', 'geometry', 'marker']}
      />
      <header
        ref={ref}
        className={twMerge(
          'prevent-scrollbar-layout-shift mrcool-navbar-header header-sticky w-full text-sm',
          navBGColor,
          !resetColorNavbar && removeFix ? props.colorHeader : '',
          isVisible ? 'visible-sticky' : 'hidden-sticky'
        )}
        style={
          removeFix
            ? { position: 'relative', transition: 'ease-in' }
            : { position: 'fixed', zIndex: 2000 }
        }
      >
        <nav
          className='bg-y mx-auto flex w-full max-w-[90rem] flex-wrap px-4 py-2 lg:flex-nowrap lg:items-center lg:justify-between lg:py-0'
          aria-label='Global'
        >
          <div className='flex w-full items-center justify-between lg:w-auto lg:flex-grow-0 lg:justify-start'>
            <div className='flex-1'>
              {!isMobileMenuOpen && (
                <Link
                  href={{ pathname: '/' }}
                  scroll={true}
                  onClick={() => setCategory(null)}
                  className='mrcool-navbar-logo flex items-center lg:py-3 lg:pr-5'
                >
                  <img
                    src={
                      scrollNavbar.color || resetColorNavbar
                        ? navLogoImg
                        : props.logoImages && !scrollNavbar.color && removeFix
                          ? props.logoImages
                          : navLogoImg
                    }
                    alt='logo'
                    className={`mrcool-navbar-logo-img h-10 w-full max-w-40`}
                  />
                </Link>
              )}
            </div>

            <div className='mrcool-navbar-actions flex items-center gap-x-4 lg:hidden'>
              {!isMobileMenuOpen && (
                <>
                  <Button
                    onClick={onOpen}
                    variant='custom'
                    type='button'
                    className='mrcool-navbar-search-btn'
                  >
                    <Icon
                      icon={MdSearch}
                      className={twMerge(
                        'mrcool-navbar-search-icon size-6',
                        pageThemebase.desktop.color.default,
                        `hover:${pageThemebase.desktop.color.hover}`
                      )}
                    />
                  </Button>

                  <Link
                    href={{ pathname: 'https://portal.mrcool.com' }}
                    target='_blank'
                    className='mrcool-navbar-partner-icon'
                  >
                    <Icon
                      icon={MdGroups}
                      className={twMerge(
                        'size-8',
                        pageThemebase.desktop.color.default,
                        `hover:${pageThemebase.desktop.color.hover}`
                      )}
                    />
                  </Link>
                </>
              )}

              <button
                type='button'
                className={`mrcool-navbar-mobile-menu-btn ${isMobileMenuOpen ? 'mt-1.5' : ''}`}
                onClick={toggleMobileMenu}
                aria-expanded={isMobileMenuOpen}
                aria-label='Toggle navigation'
              >
                <Icon
                  icon={isMobileMenuOpen ? MdClose : MdMenu}
                  className={twMerge(
                    'mrcool-navbar-mobile-menu-icon size-8',
                    pageThemebase.desktop.color.default,
                    `hover:${pageThemebase.desktop.color.hover}`
                  )}
                />
              </button>
            </div>
          </div>

          <div
            className={`mrcool-navbar-menu ${
              isMobileMenuOpen
                ? 'fixed left-0 right-0 top-[calc(100%)] z-50 flex h-[calc(100dvh-100%)] w-full flex-col overflow-y-auto bg-white px-4'
                : 'hidden'
            } basis-full lg:!static lg:!flex lg:!h-auto lg:flex-grow lg:basis-auto lg:overflow-visible lg:bg-transparent lg:pt-0`}
          >
            <div className='hidden xl:block'>
              <LocationWidget />
            </div>

            <div
              className={twMerge(
                'mrcool-navbar-menu-inner ml-10 mt-10 flex flex-col items-start gap-4 text-2xl font-semibold',
                isMobileMenuOpen
                  ? pageThemebase.mobile.color.default
                  : pageThemebase.desktop.color.default,
                isMobileMenuOpen
                  ? `hover:${pageThemebase.mobile.color.hover}`
                  : `hover:${pageThemebase.desktop.color.hover}`,
                'lg:ml-0 lg:mt-0 lg:w-full lg:flex-row lg:items-center lg:justify-center lg:text-xs lg:font-medium'
              )}
            >
              <button
                onClick={() => onOpenDrawer('all')}
                className={twMerge(
                  'mrcool-navbar-all-products-btn inline-flex',
                  isMobileMenuOpen
                    ? pageThemebase.mobile.color.default
                    : pageThemebase.desktop.color.default,
                  isMobileMenuOpen
                    ? `hover:${pageThemebase.mobile.color.hover}`
                    : `hover:${pageThemebase.desktop.color.hover}`,
                  dataProduct?.id === 'all'
                    ? 'lg:border-b-2 lg:border-primary lg:text-primary'
                    : ''
                )}
              >
                All Products
              </button>
              {props.nav?.map((item, index: number) => {
                if (!item.dropdown) {
                  return (
                    <CustomLink
                      key={index}
                      href={{ pathname: item?.url || '/' }}
                      pages={item?.name?.toLowerCase() || ''}
                      target={!item?.target ? '_parent' : '_blank'}
                      variant='custom'
                      className={twMerge(
                        'mrcool-navbar-menu-link no-hover-mobile font-semibold no-underline lg:font-medium',
                        isMobileMenuOpen
                          ? pageThemebase.mobile.color.default
                          : pageThemebase.desktop.color.default,
                        isMobileMenuOpen
                          ? `hover:${pageThemebase.mobile.color.hover}`
                          : `hover:${pageThemebase.desktop.color.hover}`,
                        isDropdownProducts
                          ? 'lg:border-b-2 lg:border-primary lg:font-bold lg:text-primary'
                          : '',
                        pathname === item?.url
                          ? 'lg:text-primary lg:underline'
                          : ''
                      )}
                    >
                      {item?.name || ''}
                    </CustomLink>
                  );
                } else {
                  return (
                    <button
                      key={index}
                      onClick={() => onOpenDrawer(item?.id, item)}
                      className={twMerge(
                        'mrcool-navbar-dropdown-btn no-hover-mobile inline-flex text-left font-semibold lg:font-medium',
                        isMobileMenuOpen
                          ? pageThemebase.mobile.color.default
                          : pageThemebase.desktop.color.default,
                        isMobileMenuOpen
                          ? `hover:${pageThemebase.mobile.color.hover}`
                          : `hover:${pageThemebase.desktop.color.hover}`,
                        isDrawer?.[index]?.isOpen ||
                          (category && item.name === category)
                          ? 'lg:border-b-2 lg:border-primary lg:text-primary'
                          : 'lg:border-none'
                      )}
                    >
                      {item?.name || ''}
                    </button>
                  );
                }
              })}
            </div>
          </div>

          <div className='mrcool-navbar-actions hidden items-center gap-x-2 lg:ml-auto lg:flex lg:pl-2'>
            <Button
              onClick={onOpen}
              variant='custom'
              type='button'
              className={twMerge(
                'mrcool-navbar-search-btn inline-flex items-center gap-x-2 px-1 py-1 font-medium',
                isMobileMenuOpen
                  ? pageThemebase.mobile.color.default
                  : pageThemebase.desktop.color.default,
                isMobileMenuOpen
                  ? `hover:${pageThemebase.mobile.color.hover}`
                  : `hover:${pageThemebase.desktop.color.hover}`,
                'disabled:pointer-events-none disabled:opacity-50'
              )}
            >
              <Icon
                icon={MdSearch}
                className={twMerge(
                  'mrcool-navbar-search-icon size-5',
                  isMobileMenuOpen
                    ? pageThemebase.mobile.color.default
                    : pageThemebase.desktop.color.default,
                  isMobileMenuOpen
                    ? `hover:${pageThemebase.mobile.color.hover}`
                    : `hover:${pageThemebase.desktop.color.hover}`,
                  !resetColorNavbar && removeFix
                    ? props?.navigationTextColor
                    : ''
                )}
              />
            </Button>

            <Link
              href={{ pathname: 'https://portal.mrcool.com' }}
              target='_blank'
            >
              <Button
                variant='custom'
                className={
                  'mrcool-navbar-partner-btn rounded-full border border-gray-300 px-3 py-1 text-sm'
                }
              >
                <span
                  className={twMerge(
                    'mrcool-navbar-partner-text',
                    isMobileMenuOpen
                      ? pageThemebase.mobile.color.default
                      : pageThemebase.desktop.color.default,
                    isMobileMenuOpen
                      ? `hover:${pageThemebase.mobile.color.hover}`
                      : `hover:${pageThemebase.desktop.color.hover}`,
                    !resetColorNavbar && removeFix
                      ? props?.navigationTextColor
                      : ''
                  )}
                >
                  Partner Center
                </span>
              </Button>
            </Link>
          </div>
        </nav>
      </header>

      {/* Product dropdown modal */}
      {dataProduct !== null && dataProduct?.id !== 'all' && (
        <>
          <ModalComponent
            isOpen={!!dataProduct}
            close={onClose}
            className='mrcool-all-products-modal z-[1500] min-h-fit overflow-visible rounded-b-none rounded-t-none'
            containerClass='h-auto relative'
            size='full'
            position='top'
            transition='left'
            hideBackdrop={width < 1024}
            style={{
              top: clientHeight,
              zIndex: 1500,
            }}
          >
            {width >= 1024 ? (
              // Desktop view
              <div className='mrcool-all-products-content w-full bg-white'>
                <div className='mx-auto flex w-full max-w-[85rem] gap-4 px-5 py-6'>
                  {/* Left column with dropdown title */}
                  <div className='w-1/5 flex-none'>
                    <h1 className='mb-2 text-2xl font-semibold'>
                      {dataProduct?.header?.name || ''}
                    </h1>
                  </div>

                  {/* Right column with products */}
                  <div className='mrcool-all-products-list flex-1'>
                    <h1 className='text-md mb-2 font-semibold'>Products</h1>
                    <div className='mrcool-all-products-grid grid grid-cols-5 gap-5 pb-2'>
                      {dataProduct?.productDropdown?.map(
                        (product: DropdownProduct) => (
                          <Link
                            key={product.id}
                            title={product.attributes.name}
                            className='relative h-auto w-full rounded-xl bg-[#F7F8F9] p-4'
                            href={`/product/${product.attributes.slug}`}
                            onClick={onClose}
                          >
                            <div className='flex flex-col items-center justify-start'>
                              <img
                                className='h-[150px] w-full rounded-xl rounded-b-none object-cover object-center p-2'
                                src={
                                  product.attributes.thumbnail?.data?.attributes
                                    ?.url || `/images/air-conditioner.png`
                                }
                                alt='Image Description'
                              />
                              <p className='text-center text-[15px] font-medium'>
                                {product.attributes.name}
                              </p>
                            </div>
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // Mobile view
              <div className='mrcool-mobile-modal-container px-4 py-2'>
                <div className='mrcool-mobile-modal-header flex w-full items-center justify-between lg:w-auto lg:flex-grow-0 lg:justify-start'>
                  <button
                    type='button'
                    onClick={() => {
                      setDataProduct(null);
                      setIsDrawer(
                        isDrawer?.map((item) => ({
                          ...item,
                          isOpen: false,
                        }))
                      );
                    }}
                    className='mrcool-navbar-back-btn'
                  >
                    <MdArrowBackIos className='ml-1 size-7 pt-1 text-gray-600' />
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setDataProduct(null);
                      setIsMobileMenuOpen(false);
                      onClose();
                    }}
                    className='mrcool-navbar-mobile-menu-btn mt-1.5'
                  >
                    <MdClose className='size-8 text-gray-600' />
                  </button>
                </div>
                <div className='mrcool-mobile-modal-content ml-10 mt-12 flex flex-col items-start gap-2 text-2xl text-gray-600'>
                  <h2 className='mrcool-mobile-modal-category-title text-2xl font-semibold'>
                    {dataProduct?.header?.name || ''}
                  </h2>
                  <div className='mrcool-mobile-modal-products-grid -mt-2 w-full'>
                    {dataProduct?.productDropdown?.map(
                      (product: DropdownProduct) => (
                        <button
                          key={product.id}
                          className='mrcool-mobile-modal-product-item mt-4 block w-full text-left'
                          onClick={() => {
                            handleDropdownButtonClick(product.attributes.slug);
                          }}
                        >
                          {product.attributes.name}
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
          </ModalComponent>
        </>
      )}

      {/* All products dropdown modals */}
      {dataProduct &&
        dataProduct?.id === 'all' &&
        (width >= 1024 ? (
          <MemoizedDesktopProductModal
            isOpen={!!dataProduct}
            onClose={() => onOpenDrawer('all')}
            categories={productCategories}
            selectedCategory={isSelectedCategory}
            setSelectedCategory={setIsSelectedCategory}
            productSelected={productSelected}
            isLoadingCategories={isLoadingCategories}
            clientHeight={clientHeight}
            router={router}
          />
        ) : (
          <MemoizedMobileProductModal
            isOpen={!!dataProduct}
            onClose={handleCloseModal}
            onBack={() => setDataProduct(null)}
            categories={productCategories}
            selectedCategory={isSelectedCategory}
            setSelectedCategory={setIsSelectedCategory}
            clientHeight={clientHeight}
            productSelected={productSelected || []}
            isLoadingCategories={isLoadingCategories}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        ))}

      {/* Search modal */}
      <ModalComponent
        isOpen={isOpen}
        close={onClose}
        className='mrcool-search-modal rounded-t-none'
        containerClass=''
        size='md'
        position='top'
        transition='top'
      >
        <div className='mrcool-search-input-container relative w-full'>
          <input
            type='text'
            id='hs-search-box-with-loading-1'
            name='hs-search-box-with-loading-1'
            className='mrcool-search-input block w-full border-0 border-gray-200 px-4 py-4 text-sm shadow-sm focus:z-10 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 lg:py-5 lg:ps-11'
            placeholder='Search MRCOOL®'
            autoFocus
            value={inputValue || ''}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                router.push(
                  `/components/searchResult?key=${inputValue}&tag=product`
                );
                onClose();
              }
            }}
          />
          <div
            className={`pointer-events-none absolute inset-y-0 start-0 flex items-center ps-4`}
          >
            {isLoading ? (
              <div
                className='inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-current border-t-transparent text-gray-500'
                role='status'
                aria-label='loading'
              >
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <Icon
                icon={MdSearch}
                className={`hidden h-5 w-5 text-gray-500 sm:block`}
              />
            )}
          </div>

          <div className='mrcool-search-close-btn-container absolute inset-y-0 end-0 z-[80] flex items-center pe-4'>
            <button
              className='mrcool-search-close-btn'
              type='button'
              onClick={!inputValue ? onClose : clearInput}
            >
              <Icon icon={MdClose} className='size-5' />
            </button>
          </div>
        </div>

        <div className='mrcool-search-results overflow-y-auto py-2'>
          <div className='mrcool-search-results-content mb-3 w-full text-sm'>
            <div className='mrcool-search-results-container px-4'>
              <h1 className='mrcool-search-results-title sticky top-0 z-10 bg-white py-4 font-semibold'>
                {dataProducts.length > 0 && 'Product'}
              </h1>
              <div className='mrcool-search-results-list flex w-full flex-col'>
                {dataProducts.length > 0
                  ? dataProducts?.map((product) => {
                      return (
                        <button
                          key={product?.id}
                          className='px-4 py-1 text-left text-gray-600 hover:text-primary hover:underline'
                          onClick={() => {
                            router.push(
                              `/product/${product?.attributes?.slug}`
                            );
                            onClose();
                          }}
                        >
                          {product?.attributes?.name}
                        </button>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default Navbar;
