import { useMapStore } from '@/stores/map/mapStore';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useStore } from 'zustand';

export default function LocationWidget() {
  const handleLocationChange = () => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((permissionStatus) => {
        if (permissionStatus.state === 'granted') {
          getCurrentPosition();
        }
      });
  };

  const { yourAddress, getCurrentPosition, browserLocationBlocked } = useStore(
      useMapStore,
      (state) => ({
        getCurrentPosition: state.getCurrentPosition,
        yourAddress: state.yourAddress,
        browserLocationBlocked: state.browserLocationBlocked,
      })
    ),
    [city, setCity] = useState<string>(''),
    [state, setState] = useState<string>(''),
    [country, setCountry] = useState<string>(''),
    [from, setFrom] = useState<string>(''),
    [hasLocation, setHasLocation] = useState<boolean>(false);

  useEffect(() => {
    if (yourAddress?.address_components.length) {
      const city =
        yourAddress.address_components.find((c) => c.types.includes('locality'))
          ?.long_name || '';
      const state =
        yourAddress.address_components.find((c) =>
          c.types.includes('administrative_area_level_1')
        )?.short_name || '';
      const country =
        yourAddress.address_components.find((c) => c.types.includes('country'))
          ?.long_name || '';
      const from =
        yourAddress.from === 'ip'
          ? 'IP address'
          : yourAddress.from === 'geoloc'
            ? 'Device'
            : '';

      setCity(city);
      setState(state);
      setCountry(country);
      setFrom(from);
      setHasLocation(true);
    } else {
      setHasLocation(false);
    }
  }, [yourAddress]);

  useEffect(() => {
    getCurrentPosition();
  }, []);

  return hasLocation ? (
    <div className='w-[250px] text-xs'>
      <div>
        {city} {state}, {country}
      </div>
      <div>
        From your {from}
        {from === 'IP address' && !browserLocationBlocked && (
          <>
            {' '}
            &bull;{' '}
            <Link onClick={handleLocationChange} href={'#'}>
              update?
            </Link>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className='w-min-[225px] text-xs'>
      <Skeleton className='w-[225px]' />
      <Skeleton className='w-[225px]' />
    </div>
  );
}
